import { DataGrid } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  SelectChangeEvent,
  Box,
  Button,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
} from '@mui/material';
import { MainContainer } from '../MainDashboard/StyledComponents';
import { useGetOutputAnnotationTable } from '@/api/result/result';
import { useGetAnnotationSource } from '@/api/annotationSource'; // Import the hook to fetch annotation sources

type Column = { field: string; headerName?: string; flex?: number };
type Row = { id: string; [key: string]: string | number | null };

const Result = () => {
  const { outputID } = useParams<{ outputID: string }>();
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [appliedSources, setAppliedSources] = useState<string[]>([]); // State for applied sources

  const [rowsData, setRows] = useState<Row[]>([]);
  const [columns, setColumns] = useState<Column[]>([]);
  const [loading, setLoading] = useState(true);

  const [cursor, setCursor] = useState<string | null>(null); // Cursor state
  const [currentCursor, setCurrentCursor] = useState<string | null>(null); // Controlled cursor
  const [rowCount, setRowCount] = useState(0); // Total rows count
  const [shouldFetch, setShouldFetch] = useState(false); // Control fetching

  // Sync cursor to currentCursor
  useEffect(() => {
    if (shouldFetch) {
      setCurrentCursor(cursor); // Sync cursor to currentCursor
      setShouldFetch(false); // Reset the flag
    }
  }, [cursor, shouldFetch]);

  // Fetch annotation sources dynamically
  const { data: annotationSources = [], isLoading: sourcesLoading } =
    useGetAnnotationSource();

  const { data: outputData, isLoading: dataLoading } =
    useGetOutputAnnotationTable(
      outputID,
      appliedSources,
      currentCursor || undefined, // Fetch only when currentCursor is defined
    );

  useEffect(() => {
    if (!outputData) return;

    const { headers, rows, next } = outputData.results as {
      headers: string[];
      rows: Record<string, string | number | null>[];
      next: string | null;
    };

    // Configuration for column handling
    const hideColumns = new Set(['id']); // Columns to hide
    const excludeColumns = new Set(['']); // Columns to exclude completely

    // Dynamically generate columns, excluding hidden and excluded columns
    const generatedColumns = headers
      .filter(
        (header) => !excludeColumns.has(header) && !hideColumns.has(header),
      )
      .map((header) => ({
        field: header,
        headerName: header.replace(/_/g, ' '), // Format header names
        flex: 1, // Adjust column width
      }));

    // Format rows to include 'id', replace null values, and exclude unwanted columns
    const formattedRows: Row[] = rows.map((row) =>
      Object.entries(row).reduce((acc, [key, value]) => {
        if (!excludeColumns.has(key)) {
          acc[key] = value === null ? '-' : value; // Replace nulls with '-'
        }
        return acc;
      }, {} as Row),
    );

    setColumns(generatedColumns);
    setRows(formattedRows);
    setCursor(next); // Update cursor for the next page
    setRowCount(outputData.row_count || 0);
    setLoading(false);
  }, [outputData]);

  const handleSourceChange = (event: SelectChangeEvent<string[]>) => {
    setSelectedSources(event.target.value as string[]);
  };

  const handleApplyAnnotationSources = () => {
    // Update applied sources only when Apply is clicked
    setAppliedSources(selectedSources);
    setLoading(true); // Show loading until the data is fetched
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const extractCursor = (url: string | null): string | null => {
    if (!url) return null;

    try {
      const parsedUrl = new URL(url);
      return parsedUrl.searchParams.get('cursor'); // Extract the 'cursor' parameter
    } catch (error) {
      console.error('Failed to parse cursor URL:', error);
      return null;
    }
  };

  const handlePaginationModelChange = (model: {
    page: number;
    pageSize: number;
  }) => {
    setPaginationModel(model);

    const newCursor =
      model.page > paginationModel.page
        ? extractCursor(outputData?.next) // Extract next cursor
        : extractCursor(outputData?.previous); // Extract previous cursor

    if (newCursor) {
      setCursor(newCursor); // Update the cursor
      setShouldFetch(true); // Enable fetching
    }

    console.log('Cursor:', newCursor);
  };

  return (
    <MainContainer
      sx={{ height: '75vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
        <FormControl
          sx={{ minWidth: 300, marginRight: 2 }}
          disabled={sourcesLoading}
        >
          <InputLabel id="annotation-source-label">
            Annotation Sources
          </InputLabel>
          <Select
            labelId="annotation-source-label"
            multiple
            value={selectedSources}
            onChange={handleSourceChange}
            renderValue={(selected) =>
              (Array.isArray(annotationSources) ? annotationSources : [])
                .filter((source) => selected.includes(source.id.toString()))
                .map((source) => source.name)
                .join(', ')
            }
          >
            {Array.isArray(annotationSources) &&
              annotationSources.map((source) => (
                <MenuItem key={source.id} value={source.id.toString()}>
                  <Checkbox
                    checked={selectedSources.includes(source.id.toString())}
                  />
                  <ListItemText primary={source.name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleApplyAnnotationSources}
          sx={{ marginLeft: 2 }}
        >
          Apply
        </Button>
      </Box>

      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <DataGrid
          paginationMode="server"
          rows={rowsData}
          columns={columns}
          loading={loading || dataLoading}
          rowCount={rowCount}
          // pageSizeOptions={[20, 50, 100]}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick
          sx={{ height: '90%', overflowY: 'auto' }}
        />
      </Box>
    </MainContainer>
  );
};

export default Result;
