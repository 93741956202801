import { useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { get } from '@/api/httpClient';
import { AnnotationSource } from './types';
import { annotationSourceSchema } from '@/zod_schemas';

const QUERY_KEYS = {
  ANNOTATION_SOURCES: ['annotationSources'],
  ANNOTATION_SOURCE: (sourceId: string) => [
    'annotationSource',
    { id: sourceId },
  ],
};

export const useGetAnnotationSource = (sourceId?: string) =>
  useSuspenseQuery<AnnotationSource | AnnotationSource[]>({
    queryKey: sourceId
      ? QUERY_KEYS.ANNOTATION_SOURCE(sourceId)
      : QUERY_KEYS.ANNOTATION_SOURCES,
    queryFn: async () => {
      const resp = sourceId
        ? await get(`/annotation_source/${sourceId}/`)
        : await get('/annotation_source/');

      // Validate response using Zod
      const validate = Array.isArray(resp.data)
        ? z.array(annotationSourceSchema)
        : annotationSourceSchema;

      return validate.parse(resp.data);
    },
  });
