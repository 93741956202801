import { z } from 'zod';

export const registerSchema = z.object({
  first_name: z.string().nonempty({ message: 'First name is required' }),
  last_name: z.string().nonempty({ message: 'Last name is required' }),
  email: z.string().nonempty({ message: 'Email is required' }),
  password: z.string().nonempty({ message: 'Password is required' }),
});

// Define the schema
export const resetPasswordSchema = z
  .object({
    new_password: z
      .string()
      .min(6, { message: 'Password must be at least 6 characters long' })
      .nonempty({ message: 'Password is required' }),
    confirm_password: z
      .string()
      .nonempty({ message: 'Please confirm your password' }),
  })
  .refine((data) => data.new_password === data.confirm_password, {
    message: 'Passwords does not match',
    path: ['confirm_password'], // This is where the error will appear
  });

export const emailValidationSchema = z.object({
  email: z.string().min(1, { message: 'E-Mail is required' }),
});

export const loginSchema = z.object({
  email: z.string().nonempty({ message: 'E-Mail is required' }),
  password: z.string().nonempty({ message: 'Password is required' }),
  otp: z.string().nonempty({ message: 'One-Time password is required' }),
  remember: z.boolean().optional(),
});

export const logoutSchema = z.object({
  token: z.string(),
});

const sampleGender = z.enum(['male', 'female', 'unknown']);

const sampleType = z.enum(['unpaired_fastq', 'paired_fastq', 'bam']);

const sampleFileType = z.enum(['fastq_forward', 'fastq_reverse', 'bam']);

const sampleFile = z.object({
  file_path: z.string(),
  // .min(1, { message: 'Please select file' }),

  file_type: sampleFileType,
});

export const sampleSchema = z.object({
  id: z.string().optional(),
  sample_id: z.string().nonempty({ message: 'Sample id is required' }),
  gender: sampleGender,
  sample_type: sampleType,
  files: z.array(sampleFile),
  isNew: z.boolean().optional(),

  inputValue: z.string().optional(),
  // .nonempty({ message: 'At least one file is required' }),
});

export const sampleEditSchema = z.object({
  id: z.string().optional(),
  parent_id: z.string().optional(),
  encrypted_patient_id: z.string().nullable(),
  sample_type: sampleType,
  gender: sampleGender,
  files: z.array(sampleFile),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  sample_id: z.string().nonempty({ message: 'Sample id is required' }),
  clinical_history: z.string().optional(),
  symptomatic_status: z.boolean(),
  phenotype: z.array(z.string()),
  active: z.boolean(),
  created_by: z.string().optional(),
  isNew: z.boolean().optional(),
  inputValue: z.string().optional(),
  // .nonempty({ message: 'At least one file is required' }),
});

export const sampleIDSchema = z.object({
  id: z.string().optional(),
});

const experimentType = z.enum(['single', 'trio', 'custom']);

const experimentStatus = z.enum([
  'pending',
  'completed',
  'in_progress',
  'failed',
  'waiting',
]);

export const experimentSchema = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: 'Experiment name is required' }),
  description: z.string().optional(),
  pipeline_configs: z.array(z.string()),
  // .min(1, { message: 'Please select at least one pipeline' }),
  experiment_type: experimentType,
  status: experimentStatus.optional(), // coming from the server and is read-only
  experiment_id: z.string().optional(), // coming from the server and is read-only
  samples: z.array(sampleSchema).nonempty({
    message: 'At least one sample is required',
  }),
});

export const testSamples = z.object({
  id: z.string().optional(),
  parent_id: z.string().optional(),
  encrypted_patient_id: z.string().nullable(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  sample_id: z.string().nonempty({ message: 'Sample id is required' }),
  clinical_history: z.string().optional(),
  symptomatic_status: z.boolean().nullable(),
  phenotype: z.array(z.string()).nullable(),
  active: z.boolean(),
  created_by: z.string().optional(),
  gender: sampleGender,
  sample_type: sampleType,
  files: z.array(sampleFile),
  isNew: z.boolean().optional(),
  inputValue: z.string().optional(),
  // .nonempty({ message: 'At least one file is required' }),
});

export const groupExperimentSchema = z.object({
  id: z.string().optional(),
  ids: z.string().optional(),
  samples: z.array(testSamples).nonempty({
    message: 'At least one sample is required',
  }),
  parent_id: z.string().optional(),
  name: z.string().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  experiment_type: experimentType.optional(),
  status: experimentStatus.optional(), // coming from the server and is read-only
  active: z.boolean().optional(),
  result: z
    .object({
      additionalProp1: z.string().optional(),
      additionalProp2: z.string().optional(),
      additionalProp3: z.string().optional(),
    })
    .nullable(),
  created_by: z.string().optional(),
  experiment_group: z.string().optional(),
  pipeline_config: z
    .object({
      id: z.string(),
      name: z.string(),
      job_queue_arn: z.string(),
      job_definition_arn: z.string(),
      pipeline_command: z.array(z.string()), // Array of strings
      config: z
        .object({
          additionalProp1: z.string(),
          additionalProp2: z.string(),
          additionalProp3: z.string(),
        })
        .partial(), // Makes all config properties optional
      active: z.boolean(),
      created_at: z.string(),
      pipeline: z.string(),
      created_by: z.string(),
    })
    .partial(),
});

export const experimentEditSchema = z.object({
  id: z.string().optional(),
  experiments: z.array(groupExperimentSchema),
  name: z.string().nonempty({ message: 'Experiment name is required' }),
  description: z.string().nullable(),
  experiment_type: experimentType.optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  active: z.boolean(),
  project: z.string().optional(),
  created_by: z.string().optional(),
});

export const experimentSchemaID = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: 'Experiment name is required' }),
  description: z.string().optional(),
  pipeline_configs: z.array(z.string()).optional(),
  // .min(1, { message: 'Please select at least one pipeline' }),
  experiment_type: experimentType,
  status: experimentStatus.optional(), // coming from the server and is read-only
  experiment_id: z.string().optional(), // coming from the server and is read-only
  samples: z.array(z.string()).optional(),
});

export const experimentBatchSchema = z.object({
  id: z.string().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  experiment_type: experimentType.optional(),
  status: experimentStatus.optional(), // coming from the server and is read-only
  experiment_id: z.string().optional(), // coming from the server and is read-only
  samples: z
    .array(
      z.object({
        sample_id: z.string().min(1, { message: 'Sample ID is required' }),
      }),
    )
    .nonempty({
      message: 'At least one sample is required',
    }),
});

export const projectSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: 'Project name is required' }),
  description: z.string().optional(),
  project_type: z.string(),
  experiments: z.array(experimentSchema).nonempty({
    message: 'At least one experiment is required',
  }),
});

export const projectListSchema = z.object({
  created_at: z.string(),
  created_by: z.object({
    id: z.string(),
    first_name: z.string(),
    last_name: z.string(),
  }),
  description: z.string(),
  experiment_group_count: z.number(),
  id: z.string(),
  name: z.string(),
  project_type: z.string(),
  updated_at: z.string(),
});

export const projectExpSchema = z.object({
  id: z.string(),
  name: z.string(),
  active: z.boolean(),
});

export const objectTest = z.object({
  experiment_details: z.array(projectExpSchema),
  experiments: z.array(experimentEditSchema),
});

export const projectEditSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: 'Project name is required' }),
  description: z.string().optional(),
  project_type: z.string().optional(),
  experiment_groups: z.array(projectExpSchema).optional(),
});

export const projectSchemaID = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: 'Project name is required' }),
  description: z.string().optional(),
  project_type: z.string(),
  experiments: z.array(experimentSchemaID).nonempty({
    message: 'At least one experiment is required',
  }),
});

export const optionsConfigSchema = z.object({
  id: z.string(),
  value: z.union([z.string(), z.number()]),
});

export const pipelineConfigSchema = z
  .object({
    id: z.string().optional(),
    allowEmpty: z.boolean(),
    name: z.string(),
    type: z.enum(['textfield', 'displayfield', 'dropdown']),
    value: z.union([
      z.string(),
      z.number(),
      z.array(z.union([z.string(), z.number()])),
    ]),
    input_type: z.enum(['string', 'number']),
    multiple: z.boolean().optional(), // only required for dropdown
    options: z.array(optionsConfigSchema).nonempty({
      message: 'Please add sample',
    }),
  })
  .superRefine((data, ctx) => {
    // Validate input optional
    if (
      data.allowEmpty === false &&
      (data.value === null || data.value === undefined || data.value === '')
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fields this input fields',
        path: ['value'],
      });
    }

    // Validate the data based on the pipeline config
    if (data.input_type === 'number' && typeof data.value !== 'number') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid value. Please use number',
        path: ['value'],
      });
    } else if (data.input_type === 'string' && typeof data.value !== 'string') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid value. Please use string',
        path: ['value'],
      });
    }
  });

export const pipelineSchema = z.object({
  id: z.string(),
  main_pipelineID: z.string().optional(),
  name: z.string().min(1, { message: 'Pipeline name is required' }),
  description: z
    .string()
    .min(1, { message: 'Pipeline description is required' }),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  configs: z.array(pipelineConfigSchema).optional(),
});

export const sampleBatch = z.object({
  id: z.string().optional(),
  name: z.string().nonempty({ message: 'Sample ID is required' }),
  experiment_type: experimentType,
  samples: z
    .array(
      z.object({
        sample_id: z.string().nonempty({ message: 'Sample id is required' }),
      }),
    )
    .nonempty({
      message: 'At least one sample is required',
    }),
});

export const batchSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: 'Batch name is required' }),
  description: z.string().optional(),
  project_type: z.string(),
  bam_file: z.string().optional(),
  experiments: z.array(experimentSchema).nonempty({
    message: 'Please add sample',
  }),
});

const patientGender = z.enum(['male', 'female', 'unknown']);

export const additionalSchema = z.object({
  id: z.string().optional(),
  additional_sample_id2: z.string().optional(),
  additional_dob2: z.coerce.date().min(new Date(0)),
  additional_ethnicity2: z.array(z.string()),
  additional_symptomatic2: z.string().optional(),
  additional_specimen_date2: z.coerce.date().min(new Date(0)),
});

export const hpoSchema = z.object({
  id: z.string().optional(),
  hpo_id: z.string().optional(),
  name: z.string().optional(),
  definition: z.string().optional(),
  comment: z.string().optional(),
  descendant_count: z.number().nullable(),
  synonyms: z.array(z.string()),
  xrefs: z.array(z.string()),
  translations: z.string().nullable(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  created_by: z.string().optional(),
});

export const ethnicitySchema = z.array(
  z.object({
    id: z.number(),
    name: z.string().min(3, { message: 'Not enough characters' }),
    description: z.string().optional(),
  }),
);

export const patientSchema = z.object({
  // Patient Details
  name: z.string().min(3, { message: 'Patient name is required' }),
  id: z.string().optional(),
  identification: z
    .string()
    .min(1, { message: 'Required' })
    .refine((val) => val.length >= 3, {
      message: 'Not enough characters',
    }),
  ethnicity: z.array(z.string()),
  date_of_birth: z
    .string()
    .refine(
      (str) =>
        /^\d{4}-\d{2}-\d{2}$/.test(str) &&
        new Date(str).toISOString().startsWith(str),
      {
        message: 'Required',
      },
    ),
  gender: patientGender,

  // HPO Details
  // hpo_ids: z.array(hpoSchema).optional(),
  samples: z.array(sampleSchema).optional(),
  hpo_ids: z.array(z.string()).optional(),
  sample_ids: z.array(z.string()).optional(),
});

export const sampleResultSchema = z.object({
  active: z.boolean(),
  created_at: z.string(),
  created_by: z.object({
    id: z.string(),
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
  }),
  experiment_group: z.object({
    id: z.string(),
    name: z.string(),
  }),
  experiment_type: z.string(),
  id: z.string(),
  parent_id: z.string(),
  pipeline: z.object({
    id: z.string(),
    name: z.string(),
  }),
  pipeline_config: z.object({
    id: z.string(),
    name: z.string(),
  }),
  project: z.string(),
  result: z.string().nullable(),
  samples: z.array(
    z.object({
      id: z.string(),
      sample_id: z.string(),
    }),
  ),
  status: z.string(),
  updated_at: z.string(),
  outputs: z.array(
    z.object({
      id: z.string(),
      file_extension: z.string(),
      created_at: z.string(),
    }),
  ),
});

export const permissionSchema = z.object({
  id: z.union([z.string(), z.number()]).optional(),
  name: z
    .string()
    .min(1, { message: 'Group name is required' })
    .refine((val) => val.length >= 6, {
      message: 'Mininum group name must be atleast 6 characters',
    }),
  description: z.string().optional(),
  permissions: z.array(z.number()).optional(),
});

export const permissionContentTypeSchema = z.object({
  content_type: z.object({
    id: z.string(),
    app_label: z.string(),
    model: z.string(),
  }),
  permissions: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
      description: z.string(),
      allowed: z.boolean().optional(),
    }),
  ),
});

export const permissionType = z.object({
  id: z.string(),
  name: z.string(),
  codename: z.string(),
  content_type: z.string(),
});

export const userListsSchema = z.object({
  id: z.string(),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  date_joined: z.string().optional(),
  user_permission: z.array(z.string().optional()).optional(),
});

export const outputIDExperimentSchema = z.object({
  id: z.string(),
  file_extension: z.string(),
  created_at: z.string(),
  experiment: z.string(),
  user_file: z.string(),
});

export const outputIDAnnotationTable = z.object({
  next: z.string().nullable(),
  previous: z.string().nullable(),
  results: z.object({
    headers: z.array(z.string()),
    rows: z.array(
      z.record(z.string(), z.union([z.string(), z.number(), z.null()])),
    ),
  }),
  row_count: z.number(),
});

export const annotationSourceSchema = z.object({
  id: z.number(), // Use z.string() if IDs are UUIDs
  name: z.string(),
  description: z.string(),
  url: z.string().nullable(),
  created_at: z.string(), // ISO 8601 format
});

export type RegisterSchema = z.infer<typeof registerSchema>;
export type ResetSchema = z.infer<typeof registerSchema>;
export type LoginSchema = z.infer<typeof loginSchema>;
export type LogoutSchema = z.infer<typeof logoutSchema>;
export type RequestOtpSchema = Omit<LoginSchema, 'otp' | 'remember'>;
export type ProjectSchema = z.infer<typeof projectSchema>;
export type ProjectListSchema = z.infer<typeof projectListSchema>;
export type ProjectEditSchema = z.infer<typeof projectEditSchema>;
export type ProjectSchemaID = z.infer<typeof projectSchemaID>;
export type BatchSchema = z.infer<typeof batchSchema>;
export type SampleBatchSchema = z.infer<typeof sampleBatch>;
export type PatientSchema = z.infer<typeof patientSchema>;
export type EthnicitySchema = z.infer<typeof ethnicitySchema>;
export type ExperimentTypeSchema = z.infer<typeof experimentType>;
export type ExperimentSchemaID = z.infer<typeof experimentSchemaID>;
export type ExperimentSchema = z.infer<typeof experimentSchema>;
export type ExperimentEditSchema = z.infer<typeof experimentEditSchema>;
export type PipelineConfigSchema = z.infer<typeof pipelineConfigSchema>;
export type SampleSchema = z.infer<typeof sampleSchema>;
export type SampleIDSchema = z.infer<typeof sampleIDSchema>;
export type OptionsConfigSchema = z.infer<typeof optionsConfigSchema>;
export type SampleGenderSchema = z.infer<typeof sampleGender>;
export type SampleFileTypeSchema = z.infer<typeof sampleType>;
export type SampleFileSchema = z.infer<typeof sampleFile>;
export type ExperimentStatusSchema = z.infer<typeof experimentStatus>;
export type PipelineSchema = z.infer<typeof pipelineSchema>;
export type HPOSchema = z.infer<typeof hpoSchema>;
export type EmailValidationSchema = z.infer<typeof emailValidationSchema>;
export type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;
export type GroupExperimentSchema = z.infer<typeof groupExperimentSchema>;
export type TestSamples = z.infer<typeof testSamples>;
export type SampleResultSchema = z.infer<typeof sampleResultSchema>;
export type PermissionSchema = z.infer<typeof permissionSchema>;
export type PermissionContentTypeSchema = z.infer<
  typeof permissionContentTypeSchema
>;
export type PermissionType = z.infer<typeof permissionType>;
export type UserListsSchema = z.infer<typeof userListsSchema>;
export type OutputIDExperimentSchema = z.infer<typeof outputIDExperimentSchema>;
export type OutputIDAnnotationTableSchema = z.infer<
  typeof outputIDAnnotationTable
>;
export type AnnotationSourceSchema = z.infer<typeof annotationSourceSchema>;
